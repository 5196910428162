<template>
    <div class="home_box">
        <div class="o_info_top">
            <div class="o_info_top_1">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">{{$t('main.text50')}}</el-breadcrumb-item>
                    <el-breadcrumb-item><span class="o_info_top">{{$t('main.text51')}}</span></el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="o_info_top2_1">
                <div>
                    <div class="o_info_top2_2">{{$t('main.text52')}}</div>
                    <div class="o_info_top2_3">{{top_data.order_id}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text264')}}</div>
                    <div class="o_info_top2_3">{{top_data.order_username}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text265')}}</div>
                    <div class="o_info_top2_3">{{time_format(top_data.c_time)}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text285')}}</div>
                    <div class="o_info_top2_3">{{top_data.interviewer?.name}}</div>
                </div>
                <!-- <div>
                    <div class="o_info_top2_2">面试时间</div>
                    <div class="o_info_top2_3">XXX</div>
                </div> -->
                <div>
                    <div class="o_info_top2_2">{{$t('main.text92')}}</div>
                    <div class="o_info_top2_3">
                        <!-- 状态 1未开始;5待处理;10安排中;15进行中;20已完成 -->
                        <span class="type1" v-if="top_data.status==1">{{$t('main.text266')}}</span>
                        <span class="type1" v-else-if="top_data.status==5">{{$t('main.text267')}}</span>
                        <span class="type1" v-else-if="top_data.status==10">{{$t('main.text268')}}</span>
                        <span class="type1" v-else-if="top_data.status==15">{{$t('main.text269')}}</span>
                        <span class="type1" v-else-if="top_data.status==20">{{$t('main.text270')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="h_table">
            <div class="h_table_top"> 
                <div class="t_top_1">{{$t('main.text51')}}</div>
            </div>
            <el-table
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            style="width: 100%">
                <el-table-column :label="$t('main.text70')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text85')">
                    <span slot-scope="props">
                        {{ props.row.name }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text223')">
                    <span slot-scope="props">
                        <div>{{ props.row.mobile }}</div>
                        <div>{{ props.row.email }}</div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text271')" min-width="120">
                    <span slot-scope="props">
                        <div>
                            <span v-if="props.row.start_time">{{ time_format(props.row.start_time) }}</span>
                            <span v-else>-</span>
                        </div>
                        <div>
                            <span v-if="props.row.end_time">{{ time_format(props.row.end_time) }}</span>
                            <span v-else>-</span>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text92')">
                    <span slot-scope="props" class="state_color">
                        <!-- "schedule_status": 1, //状态 1 处理中; 2 面试官未回复面试时间; 5 候选人联系中; 6 候选人联系中; 7 候选人要求调整待面试官确认; 
                        8 候选人放弃; 10 候选人联系方式错误; 11 面试安排暂停; 15 候选人联系中; 20 面试取消; 25 候选人放弃; 26 面试取消; 
                        30 候选人要求调整待面试官确认; 31 候选人要求调整待面试官确认; 32 面试取消; 33 待面试官确认; 35 面试已安排; 
                        36 面试改期; 37 候选人放弃; 40 面试已完成; 45 候选人爽约; 50 面试安排终止 -->
                        <span v-if="props.row.schedule_status==1"><span class="dot1"></span>{{$t('main.text55')}}</span>
                        <span v-else-if="props.row.schedule_status==2"><span class="dot1 dot_bgc2"></span>{{$t('main.text75')}}</span>
                        <span v-else-if="props.row.schedule_status==5"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==6"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==7"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==8"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==10"><span class="dot1 dot_bgc2"></span>{{$t('main.text60')}}</span>
                        <span v-else-if="props.row.schedule_status==11"><span class="dot1 dot_bgc2"></span>{{$t('main.text61')}}</span>
                        <span v-else-if="props.row.schedule_status==15"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==20"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==25"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==26"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==30"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==31"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==32"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==33"><span class="dot1 dot_bgc2"></span>{{$t('main.text63')}}</span>
                        <span v-else-if="props.row.schedule_status==35"><span class="dot1 dot_bgc2"></span>{{$t('main.text64')}}</span>
                        <span v-else-if="props.row.schedule_status==36"><span class="dot1 dot_bgc2"></span>{{$t('main.text65')}}</span>
                        <span v-else-if="props.row.schedule_status==37"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==40"><span class="dot1 dot_bgc2"></span>{{$t('main.text66')}}</span>
                        <span v-else-if="props.row.schedule_status==45"><span class="dot1 dot_bgc2"></span>{{$t('main.text67')}}</span>
                        <span v-else-if="props.row.schedule_status==50"><span class="dot1 dot_bgc2"></span>{{$t('main.text68')}}</span>
                        <span v-else>-</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text100')" min-width="120">
                    <span slot-scope="props">
                        <div style="margin-bottom: 5px;" v-if="[5,6,15,7,30,31,33].includes(props.row.schedule_status)">
                            <el-button plain size="mini" class="termination_btn" @click="open_all_dlg(props.row,1)">{{$t('main.text290')}}</el-button>
                        </div>
                        <div style="margin-bottom: 5px;" v-if="[35].includes(props.row.schedule_status)">
                            <el-button plain size="mini" class="termination_btn" @click="open_all_dlg(props.row,2)">{{$t('main.text5')}}</el-button>
                        </div>
                        <div style="margin-bottom: 5px;" v-if="[5,6,15,7,30,31,11,33].includes(props.row.schedule_status)">
                            <el-button plain size="mini" class="termination_btn" @click="open_all_dlg(props.row,3)">{{$t('main.text291')}}</el-button>
                        </div>
                        <div style="margin-bottom: 5px;" v-if="[35].includes(props.row.schedule_status)">
                            <el-button plain size="mini" class="blue_bgc" @click="open_all_dlg(props.row,4)">{{$t('main.text272')}}</el-button>
                        </div>
                        <div v-if="[10,11].includes(props.row.schedule_status)">
                            <el-button plain size="mini" class="continue_btn" @click="open_all_dlg(props.row,5)">{{$t('main.text273')}}</el-button>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text274')">
                    <span slot-scope="props">
                        <span class="details" @click="to_candidate_info_history(props.row)">{{$t('main.text94')}}</span>
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 所有操作 -->
        <el-dialog
            title=" "
            :visible.sync="all_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">
                <div v-if="dlg_index==1">{{$t('main.text275')}}</div>
                <div v-else-if="dlg_index==2">{{$t('main.text276')}}</div>
                <div v-else-if="dlg_index==3">{{$t('main.text277')}}</div>
                <div v-else-if="dlg_index==4">{{$t('main.text278')}}</div>
                <div v-else-if="dlg_index==5">{{$t('main.text279')}}</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="all_dlg_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="all_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
    
<script>
import {workOrder_get,modifyInterviewTime,cancelSchedule,restartCandidate,pauseCandidate,terminateCandidate} from '@/api/api';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            order_id:this.$route.query.order_id,
            top_data:{},

            api_order_id:"",
            dlg_id:"",
            dlg_index:1,
            all_dlg:false,

        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        async get_list(){
            const res = await workOrder_get({
                order_id:this.order_id
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            // console.log(res.data.data);
            this.tableData = res.data.data.candidates;
            this.total = res.data.data.countAll;
            let {order_id,order_userid,order_username,c_time,interviewer,status} = res.data.data;
            this.top_data = {
                order_id,
                order_userid,
                order_username,
                c_time,
                interviewer,
                status, //状态 1待处理；5进⾏中；10已完成；15暂停中；20已取消
            }
            this.api_order_id = res.data.data.order_id;
        },
        to_candidate_info_history(row){
            row;
            this.$router.push({
                name:'candidate_info_history',
                query:{
                    h_order_id:this.api_order_id,
                    h_userid:row.userid,
                    form_type:"order",//工单进入
                }
            })
        },

        open_all_dlg(row,ind){
            this.dlg_id = row.userid;
            this.dlg_index = ind;
            this.all_dlg = true;
        },
        async all_dlg_confirm(){
            let res = {};
            if(this.dlg_index==1){
                res = await pauseCandidate({
                    order_id:this.api_order_id,
                    candidate_userid:this.dlg_id,
                });
            }else if(this.dlg_index==2){
                res = await cancelSchedule({
                    order_id:this.api_order_id,
                    candidate_userid:this.dlg_id,
                });
            }else if(this.dlg_index==3){
                res = await terminateCandidate({
                    order_id:this.api_order_id,
                    candidate_userid:this.dlg_id,
                });
            }else if(this.dlg_index==4){
                res = await modifyInterviewTime({
                    order_id:this.api_order_id,
                    candidate_userid:this.dlg_id,
                });
            }else if(this.dlg_index==5){
                res = await restartCandidate({
                    order_id:this.api_order_id,
                    candidate_userid:this.dlg_id,
                });
            }

            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text45'));
            this.all_dlg = false;
            this.get_list();
        }


    },

}
</script>
    
<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
/* ---- */
.o_info_top_1{
    margin-bottom: 30px;
}
.o_info_top2_1{
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 25px 20px;
    text-align: center;
    border-radius: 6px;
    background: #FFF;
    margin-bottom: 30px;
}
.o_info_top2_2{
    color: #A9A9A9;
    font-size: 16px;
    margin-bottom: 20px;
}
.o_info_top2_3{
    color: #333;
    font-size: 20px;
}
</style>
